import {Injectable, Type} from '@angular/core';
import {EditorTreeRoot} from "@sv/swagger/riva-api";

@Injectable({
  providedIn: 'root'
})
export abstract class ComponentLoaderService {

  abstract getComponent(elementEnum: EditorTreeRoot.ModuleNameEnum): Promise<Type<any> | void>

}
